// 
// buttons.scss
// Extended from Bootstrap
// 

// 
// Additional styles for theme
// 

.btn {
  letter-spacing: 0.5px;
  position: relative;
  overflow: hidden;
  outline: 0;
  margin-bottom: 6px;
}
.input-group .btn{
  margin-bottom: 0;
}
.btn-xs{
  padding: 0.4rem 0.6rem;
  font-size: 0.6rem;
  line-height: 1.2;
  border-radius: $btn-border-radius-sm;
}

// button light
.btn-light {
  background: var(--#{$prefix}light);
  border-color: var(--#{$prefix}light);
  color: var(--#{$prefix}gray-900);
  &.active, &:active, &:focus, &:hover {
    background: var(--#{$prefix}gray-200);
    border-color: var(--#{$prefix}gray-200);
    color: var(--#{$prefix}gray-900);
  }
}

// button white
.btn-white {
  background: $white;
  color: $gray-800;
  &.active, &:active, &:focus, &:hover {
    background: $gray-200;
    color: $gray-800;
  }
}
.btn-outline-white {
  background: transparent;
  border-color: $white;
  color: $white;
  &.active, &:active, &:focus, &:hover {
    color: $gray-800;
    background: $white;
  }
}

.btn-outline-light{
  background: transparent;
  border-color: $gray-400;
  color: $body-color;
	&:active, &:hover, &:focus{
		background: $gray-300;
		border-color: $gray-300;
		color: $gray-800;
	}
}
.btn-check:checked + .btn-outline-light{
  background: $gray-400;
  border-color: $gray-400;
  color: $gray-800;
}

// button round
.btn-round{
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  padding: 0;
  text-align: center;
  i{
    margin:0; 
  }
  // Quick fix for play button centered
  .fa-play{
    padding-left: 3px;
  }
  &.btn-lg{
    height: 55px;
    width: 55px;
    line-height: 55px;
  }
  &.btn-sm{
    height: 32px;
    width: 32px;
    line-height: 30px;
    i{
      font-size: .6rem;
    }
  }
  &.btn-xs{
    height: 25px;
    width: 25px;
    line-height: 24px;
    i{
      font-size: .6rem;
    }
  }
}

// Quick fix for play button centered in RTL
/*rtl:raw:
.btn-round .fa-play{
  padding-left: 3px;
  padding-right: 0;
}
*/

// button soft
@mixin button-soft-variant($color, $soft-background: rgba($color, .1)) {
  color: $color;
  background-color: $soft-background;
  &:active, &:hover, &:focus {
    color: color-contrast($color);
    background-color: $color;
    border-color: $color; 
  }
}
@each $color, $value in $theme-colors {
  .btn-#{$color}-soft {
    @include button-soft-variant($value);
  }
}

// Button primary soft check hover
.btn-primary-soft-check:hover {
  background-color: rgba(var(--bs-primary-rgb), 0.2);
  border: 1px solid  var(--#{$prefix}primary);
  color:  var(--#{$prefix}primary);
}
.btn-check:active+.btn-primary-soft-check, .btn-check:checked+.btn-primary-soft-check, .btn-primary-soft-check.active, .btn-primary-soft-check:active, .show>.btn-primary-soft-check.dropdown-toggle {
  background-color: rgba(var(--bs-primary-rgb), 0.2);
  color:  var(--#{$prefix}primary);
  border: 1px solid  var(--#{$prefix}primary);
}
.btn-check:focus + .btn, .btn-round-shadow:focus {
  box-shadow: none;
}

// Button dark check
.btn-dark-bg-check{
  border: 1px solid transparent !important;
}
.btn-check:active+.btn-dark-bg-check, .btn-check:checked+.btn-dark-bg-check, .btn-dark-bg-check.active, .btn-dark-bg-check:active, .show>.btn-dark-bg-check.dropdown-toggle {
  background-color: $gray-900;
  color: $white;
}
.btn-check:focus + .btn, .btn-round-shadow:focus {
  box-shadow: none;
}

// Advance filter see more
.btn-more {
  i{
    transition: $transition-base;
  }
  &[aria-expanded="true"] i{
    transform: rotate(180deg);
    transition: $transition-base;
    transform-origin: center;
  }
  .see-more{
    display: block;
  }
  .see-less{
    display: none;
  }
  &[aria-expanded="true"] .see-more{
    display: none;
  }
  &[aria-expanded="true"] .see-less{
    display: block;
  }
}

// Button flip icon
.btn-flip-icon{
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@include media-breakpoint-down(md){
  .btn-flip-icon{
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -50%);
    margin-top: 0.5rem;
    i{
      transform: rotate(270deg);
    }
  }
}

// Button primary check
.btn-primary-check:hover {
  background-color: var(--#{$prefix}primary);
  color: $white;
  border: $border-width solid var(--#{$prefix}primary);
}
.btn-check:active + .btn-primary-check, .btn-check:checked + .btn-primary-check, .btn-primary-check.active, .btn-primary-check:active, .show > .btn-primary-check.dropdown-toggle {
  background-color: var(--#{$prefix}primary);
  color: $white;
  border: $border-width solid var(--#{$prefix}primary);
}

// Button dark hover
.btn-dark-hover:hover{
  background-color: $gray-800 !important;
  border-color: $dark;
  color: $white !important;
}