// 
// form-control.scss
// Extended from Bootstrap
// 

//
// Additional style for theme
//

// Font size for form
.form-fs-lg {
  .form-control {
    font-weight: $font-weight-normal;
    font-size: $form-fs-lg;
  }
}
.form-fs-md {
  .form-control {
    font-weight: $font-weight-normal;
    font-size: $form-fs-md;
  }
}
.form-fs-sm {
  .form-control {
    font-weight: $font-weight-normal;
    font-size: $form-fs-sm;
  }
}

// Flatpickr remove calender icon
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

// Form Inline
.form-control-inline {
  .form-control-inline-text{
    font-family: $font-family-heading;
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.8;
    color: $dark;
    margin: 0 1rem;
  }

  .form-control{
    min-width: 200px;
    max-width: 700px;
    font-family: $font-family-heading;
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.25;
    color: $dark;
    text-align: center;
    border: 0;
    border-radius: 0;
    border-bottom: 2px dashed $dark;
    padding: 0;
    padding-left: 1rem;
    padding-right: 2rem;
    background-color: transparent !important;
    &::placeholder{
      color: $dark;
    }
  }

  .choices .choices__inner{
    min-width: 200px;
    max-width: 500px;
    border: 0;
    border-bottom: 2px dashed $dark;
    border-radius: 0;
    padding: 0;
    text-align: left;
    background: transparent;
    .choices__item{
      font-family: $font-family-heading;
      font-size: 3rem;
      font-weight: 700;
      line-height: 1.25;
      color: $dark;
      text-align: center;
      padding-left: 1rem;
      padding-right: 2rem;
    }
    .choices__placeholder{
      color: $dark !important;
      opacity: 1;
    }
  }
  .choices .choices__list--dropdown{
    text-align: left;
  }
  .choices.is-focused[data-type*=select-one] .choices__inner{
    border-color: $dark !important;
  }
  .choices[aria-expanded=true] .choices__inner{
    border-radius: 0 !important;
  }
  .choices[data-type*=select-one]:after{
    content: "";
    font-family: "Font Awesome 6 Free";
    margin-left: 0.35rem;
    font-weight: 900;
    font-size: 0.8rem;
    color: $dark;
    background-image: none;
  }
  
} 
@include media-breakpoint-down(sm){
  .form-control-inline {
    .form-control-inline-text{
      font-size: 1.5rem;
      margin-top: 10px;
    }
    .form-control{
      min-width: 0;
      font-size: 1.5rem;
      margin-top: 10px;
    }
    .choices .choices__inner{
      min-width: 0;
      .choices__item{
        font-size: 1.5rem !important;
      }
    }
  }
}

// Form border
.form-control-border{
  width: 100%;
  .form-label{
    background-color: var(--#{$prefix}mode);
    position: relative;
    z-index: 9;
    margin-left: 1rem;
    padding: 0 8px;
    border-radius: $input-border-radius;
  }
}

// Form Bg light
.form-control-bg-light {
  .form-control{
    border-color: var(--#{$prefix}light) !important;
    background-color: var(--#{$prefix}light) !important;
    &:focus{
      border-color: $input-focus-border-color !important;
    }
  }
}

// Form border bottom
.form-border-bottom{
  border-bottom: $input-border-width $border-style $input-border-color;
  .form-control {
    border: none;
  }
}

// Form with icon
.form-icon-input{
  position: relative;
    .form-control{
      color: $headings-color;
      background: var(--#{$prefix}light) !important;
      padding-left: 3.2rem;
      &::placeholder {
        color: $input-placeholder-color;
      }
    }
    .form-icon{
      position: absolute;
      transform: translateY(-50%) !important;
      top: 50%;
      left: 0;
      padding-left: 1rem;
    }
  }


  // Border transparent
  .form-border-transparent{
    .form-control{
      color: $headings-color;
      border: $border-width $border-style transparent;
      &:focus{
        border-color: $input-focus-border-color;
      }
    }
  }

  // Form control transparent
  .form-control-transparent{
    .form-control{
      background: transparent !important;
    }
  }
