// 
// dark-mode.scss
// Theme Component

@if $enable-dark-mode {

  //Dark mode styles
  .dark-mode{
    --bs-light: #2a2c31;
    --bs-gray-200: #464950;
    --bs-gray-300: #5f5f68;
    --bs-gray-400: #a1a1a8;
    --bs-gray-500: #bfc0c9;
    --bs-gray-600: #c5c6cc;
    --bs-gray-700: #ffffffb3;
    --bs-gray-800: #ffffffd9;
    --bs-gray-900: #fff;
    --bs-body-bg: #222529;
    --bs-body-bg-rgb: #222529;
    --bs-body-color: #a1a1a8;
    --bs-primary: #8e85e6 !important; //If you don't want to change primary color, then remove this variable
    --bs-primary-rgb: 142, 133, 230; //If you don't want to change primary color, then remove this variable
    --bs-link-hover-color:#7a6deb;
    --bs-mode:#191b1d;
    --bs-border-color: rgba(255, 255, 255, 0.07);

    .navbar {
    --bs-navbar-color: var(--bs-gray-600);
    --bs-navbar-hover-color: var(--bs-primary);
    --bs-navbar-disabled-color:  var(--bs-gray-400);
    --bs-navbar-active-color: var(--bs-primary);
    }
    .nav{
      --bs-nav-link-color: var(--bs-gray-600);
      --bs-nav-link-hover-color: var(--bs-primary);
    }
    .dropdown-menu {
      --bs-dropdown-bg: var(--bs-mode);
      --bs-dropdown-link-active-bg: rgba(var(--bs-primary-rgb), 0.1);
      --bs-dropdown-link-disabled-color: var(--bs-gray-200);
      box-shadow:none;
      border: 1px solid var(--bs-border-color);
    }

    .btn-primary {
      --bs-btn-bg: var(--bs-primary);
      --bs-btn-border-color: var(--bs-primary);
      --bs-btn-hover-bg: var(--bs-link-hover-color);
      --bs-btn-hover-border-color: var(--bs-link-hover-color);
      --bs-btn-active-bg: var(--bs-link-hover-color);
      --bs-btn-active-border-color: var(--bs-link-hover-color);
      --bs-btn-disabled-bg: var(--bs-primary);
    }
    .btn-outline-primary {
      --bs-btn-color: var(--bs-primary);
      --bs-btn-border-color: var(--bs-primary);
      --bs-btn-hover-bg: var(--bs-primary);
      --bs-btn-hover-border-color: var(--bs-primary);
      --bs-btn-active-bg: var(--bs-primary);
      --bs-btn-active-border-color: var(--bs-primary);
      --bs-btn-disabled-color: var(--bs-primary);
      --bs-btn-disabled-border-color: var(--bs-primary);
    }
    .btn-link {
      --bs-btn-hover-color: var(--bs-link-hover-color);
      --bs-btn-active-color: var(--bs-link-hover-color);
    }
    .modal {
      --bs-modal-bg: var(--bs-body-bg);
      --bs-modal-border-color: var(--bs-border-color-translucent);
      --bs-modal-footer-border-color: var(--bs-border-color);
    }
    .table-light {
      --bs-table-bg: var(--bs-light);
      --bs-table-color: var(--bs-gray-900);
    }
    .offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
      --bs-offcanvas-bg: var(--bs-body-bg);
    }
    
    .btn-primary-soft{
      color: var(--bs-primary);
      background-color: rgba(var(--bs-primary-rgb), 0.1);
      &:hover, &:focus{
        color: $white;
        background-color: var(--bs-primary);
        border-color: var(--bs-primary);
      }
    }
    .fill-primary{
      fill: var(--bs-primary) !important;
    }
    .fill-body{
      fill: var(--bs-body-bg) !important;
    }
    .bg-light{
      background-color: var(--bs-light) !important;
    }
    .border-light{
      border-color: var(--bs-light) !important
    }
    .bg-body{
      background-color: var(--bs-body-bg) !important;
    }
    .text-body{
      color: var(--bs-body-color) !important;
    }
    .bg-primary{
      background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
    }
    .border-primary{
      border-color: var(--bs-primary);
    }
    .text-primary{
      color: var(--bs-primary) !important;
    }
    .shadow, .shadow-lg, .shadow-sm{
      box-shadow: none !important;
    }
    .navbar-nav .dropdown .dropdown-submenu:hover>.dropdown-menu {
      box-shadow: none;
    }
    .btn-close {
      filter: invert(1) grayscale(100%) brightness(300%);
    }
  }

  // Dark mode switch
  .modeswitch-wrap {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    .modeswitch-item {
      position: relative;
      width: 54px;
      height: 27px;
      padding: 6px;
      background: var(--#{$prefix}gray-800);
      border-radius: 60px;
      display: flex;
      align-items: center;
      margin: 0 16px;
      transition: all 1s;
      &:before {
        content: "\f185";
        font-family: 'Font Awesome 6 Free';
        font-weight: 700;
        position: absolute;
        color: $dark;
      }
      &:after {
        content: "\f186";
        font-family: 'Font Awesome 6 Free';
        font-weight: 700;
        position: absolute;
        color: $white;
        top: 4%;
        right: 15%;
      }
      .modeswitch-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(-14%);
        height: 21px;
        width: 21px;
        background: $white;
        border-radius: 50%;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 20%);
        font-size: 14px;
        color: $white;
        transition: all .3s;
        z-index: 99;
        }
    }
  }

  .dark-mode .modeswitch-item {
    .modeswitch-icon {
      transform: translateX(23px);
      transition: all .3s;
      background: $white;
    }
    background: var(--#{$prefix}gray-400);
  }

  // mode switch only icon style
  .modeswitch-mini {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    display: block;
    z-index: 99999;
    .modeswitch-mini-item {
      .modeswitch-mini-icon {
        font-size: 24px;
        color: $warning;
        transition: all 0.3s;
        &:before {
          content: "\f185";
          font-family: 'Font Awesome 6 Free';
          font-weight: 700;
          border: none;
          line-height: 26px;
          text-align: center;
          display: block;
          width: 1.25rem;
        }
      }
    }
  }
  .dark-mode .modeswitch-mini .modeswitch-mini-icon:before {
    content: "\f186";
    color: $white;
  }
}

// Dark mode item hide and show
.dark-mode-item{
  display: none !important;
}

.dark-mode{
  .dark-mode-item{
    display: inline-block !important;
  }
  .light-mode-item{
    display: none !important;
  }
}